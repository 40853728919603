import { render, staticRenderFns } from "./dataList.vue?vue&type=template&id=43d4deee&scoped=true&"
import script from "./dataList.vue?vue&type=script&lang=babel&"
export * from "./dataList.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d4deee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VList})
